<template>
  <div class="box follow scroll">
    <div class="left">
      <div class="seach">
        <el-select @change="selectClose" style="width: 130px" v-model="seach.value1" clearable placeholder="请选择">
          <el-option v-for="item in seach.options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input clearable @change="inputClose" style="width: 230px;height:32px; margin-left: 16px"
          placeholder="监测方案模糊搜索" v-model="seach.value2">
          <template slot="append">
            <el-button style="width: -webkit-fill-available; color: #2e59ec" type="text"
              @click="getLeftlist">搜索</el-button>
          </template>
        </el-input>
      </div>
      <el-button @click="subScheme({ type: 'add' })" type="primary" icon="el-icon-plus">新增方案</el-button>
      <div class="left-monitoring">
        <div v-for="item in monitorData" :key="item.monitorId + item.projectName" @click="selectMonitor(item, 1)" :class="{
          content: true,
          contentshad: followdefaultId == item.monitorId,
        }">
          <div class="content-top">
            <div class="content-top-left">{{ item.projectName }}</div>
            <img class="content-top-right" src="../../assets/img/views/jiahao.png" alt @click.stop="
              subScheme({ type: 'add', monitorId: item.monitorId })
              " />
          </div>
          <div style="margin-bottom: 14px" class="content-content">
            <div class="content-content-delete" @click.stop="deletes(item.monitorId, 1)">
              <img src="../../assets/img/views/shanchu.png" alt />删除
            </div>
            <div class="content-content-edit" @click.stop="
              subScheme({ type: 'edit', monitorId: item.monitorId })
              ">
              <img src="../../assets/img/views/bianji.png" alt />编辑
            </div>
            <div class="content-content-stop" v-if="item.isRunProject == '0'" style="color: #ff8000"
              @click.stop="updateRun(item)">
              <img src="../../assets/img/views/tingzhi.png" alt />已停止
            </div>
            <div class="content-content-function" v-else-if="item.isRunProject == '1'" @click.stop="updateRun(item)">
              <img src="../../assets/img/views/yunxing.png" alt />运行中
            </div>
          </div>
          <div v-for="value in item.schemeVoList" :key="value.monitorId" @click.stop="selectMonitor(value, 1)" :class="{
            'content-bottom': true,
            contentshad2: followdefaultId == value.monitorId,
          }">
            <div class="content-top">
              <div class="content-top-left">{{ value.projectName }}</div>
              <!-- <img class="content-top-right" src="../../assets/img/views/jiahao.png" alt /> -->
            </div>
            <div class="content-content">
              <div class="content-content-delete" @click.stop="deletes(value.monitorId, 1)">
                <img src="../../assets/img/views/shanchu.png" alt />删除
              </div>
              <div class="content-content-edit" @click.stop="
                subScheme({ type: 'edit', monitorId: value.monitorId })
                ">
                <img src="../../assets/img/views/bianji.png" alt />编辑
              </div>
              <div class="content-content-stop" style="color: #ff8000" v-if="value.isRunProject == '0'"
                @click.stop="updateRun(value)">
                <img src="../../assets/img/views/tingzhi.png" alt />已停止
              </div>
              <div class="content-content-function" v-else-if="value.isRunProject == '1'" @click.stop="updateRun(value)">
                <img src="../../assets/img/views/yunxing.png" alt />运行中
              </div>
            </div>
          </div>
        </div>

        <div class="division">
          <el-divider content-position="center">公共方案</el-divider>
        </div>
        <div v-for="(iem, index) in publicmonitorData" :key="index" @click="selectMonitor(iem, 2)" :class="{
          public: true,
          contentshad3: followdefaultId == iem.pmonitorId,
        }">
          <div>{{ iem.projectName }}</div>
          <img src="../../assets/img/views/monitoringScheme/fangan.png" alt />
        </div>
      </div>
    </div>
    <div v-if="this.monitorData.length != 0 || this.publicmonitorData.length != 0" class="right">
      <div class="right-top">
        <div class="right-top-top">
          <div class="right-top-top-left">
            {{ rightview.projectName }}
            <span>（{{ allTotal }}条）</span>
          </div>
          <!-- <div class="right-top-top-right" v-if="rightview.monitorId">
            <div  @click="deletes(rightview.monitorId,1)" style="cursor: pointer;">
              <img src="../../assets/img/views/shanchu2.png" alt />删除
            </div>
            <div @click="subScheme({type:'edit',monitorId:rightview.monitorId})" style="cursor: pointer;">
              <img src="../../assets/img/views/bianji2.png" alt />编辑
            </div>
            <div>
              <img src="../../assets/img/views/yunxing2.png" alt />运行
            </div>
          </div>-->
        </div>
        <div class="right-top-bottom">
          <div>创建时间：{{ rightview.createTime }}</div>
          <div style="margin-left: 120px">最后编辑时间：{{ rightview.updateTime }}</div>
        </div>
      </div>
      <div v-if="allTotal > 0" class="right-contnet">
        <div class="right-contnet-checkbox">
          <el-tabs v-model="numb" @tab-click="handleClick">
            <el-tab-pane v-for="(item, inx) in category" @change="jump(item.platformName)" :key="inx"
              :label="item.platformName" :name="item.platformName"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="right-contnet-seach">
          <div class="right-contnet-seach-left">
            <el-select class="select" v-model="seach.region" style="width: 80px">
              <el-option label="标题" value="originalTitle"></el-option>
              <el-option label="正文" value="content"></el-option>
              <el-option label="作者" value="nickname"></el-option>
              <!-- <el-option label="来源" value="source"></el-option> -->
            </el-select>
            <el-input style="width: 240px; margin: 0 32px" placeholder="标题/作者模糊" prefix-icon="el-icon-search"
              v-model="seach.value4" clearable></el-input>
            <span class="el_font">时间</span>
            <el-date-picker v-model="timer[0]" type="date" style="width: 150px" @change="timeChange($event, 1)"
              value-format="yyyy-MM-dd" placeholder="开始日期">
            </el-date-picker>
            -
            <el-date-picker v-model="timer[1]" type="date" style="width: 150px" @change="timeChange($event, 2)"
              value-format="yyyy-MM-dd" placeholder="结束日期">
            </el-date-picker>
            <el-checkbox style="margin-left: 32px" v-model="isEarlyWarn">已预警</el-checkbox>
          </div>
          <div class="right-contnet-seach-right">
            <el-button type="primary" @click="searchList">查询</el-button>
            <el-button plain @click="resetting">重置</el-button>
          </div>
        </div>
        <div v-if="textData && textData.length > 0" class="right-contnet-content" v-loading="loading">
          <template>
            <div style="text-align: right; margin: 16px 0;padding:0 24px">
              <div style="float: left">
                <el-checkbox v-model="checkAll6" @change="handleCheckAllChange6">全选</el-checkbox>
              </div>
              <el-button class="export" :class="suspend ? 'prohibit' : ''" size="small"
                @click="exportAllData">数据导出</el-button>
              <el-button v-if="!rightview.pmonitorId" class="delete" size="small" @click="deletes(5, 5)">批量删除</el-button>
              <el-button class="copy" size="small" @click="AllCopy">批量复制</el-button>
            </div>
            <div v-for="(item, inx) in textData" :key="inx" class="right-contnet-content-div">
              <div class="right-contnet-content-div-title">
                <div class="right-contnet-content-div-title-left">
                  <el-checkbox-group v-model="checkedCities6" @change="handleCheckedCitiesChange6">
                    <el-checkbox :label="item">{{ "" }}</el-checkbox>
                  </el-checkbox-group>
                  <span class="right-contnet-content-div-title-left-span">
                    {{
                      item.mediaPlatform
                    }}
                  </span>
                  <span v-if="item.isEarlyWarn == '1'" class="comment-box-yujing">已预警</span>
                  <!-- item.originalTitle  标题 item.nickname 作者  item.commentTime 时间-->
                  <div style="max-width:931px;margin-left:16px">
                    <span class="nickname">{{ item.nickname }}:</span>
                    <span class="content">{{ item.content }}</span>
                  </div>
                </div>
              </div>
              <div class="right-contnet-content-div-content">
                <span class="originalTitle">原文标题:</span>
                <span @click="routerOpen(item.website)" class="originalTitles">{{ item.originalTitle }}</span>
              </div>
              <div class="right-contnet-content-div-tag">
                <div>{{ item.commentTime }}</div>
                <div class="right-contnet-content-div-operation-right">
                  <div class="options" @click="myCopy(item)">
                    <img src="../../assets/img/views/monitoringScheme/fuzhi.png" alt />
                    <span style="margin-left:8px">复制全部</span>
                  </div>
                  <div @click="quickCopy(item)" style="margin-left:20px;color: black;cursor: pointer">
                    <span style="margin:0;color:black;font-size:16px" class="el-icon-link"></span>
                    复制链接和标题
                  </div>
                  <div v-if="!rightview.pmonitorId" class="options" @click="deletes([item.dataId, item.monitorId], 4)"
                    style="margin-left:40px">
                    <img src="../../assets/img/views/monitoringScheme/shanchu3.png" alt />
                    <span style="margin-left:8px">删除</span>
                  </div>
                </div>
              </div>
              <!-- 二级跟评 -->
              <div v-for="(it, ind) in item.childComment" :key="ind" style="display: flex; width: 100%; margin-top: 4px">
                <div class="xiangqing-main-left-two">
                  <div style="margin-bottom: 16px">
                    <div class="main-count-box">
                      <div style="max-width:950px">
                        <span class="main-count-fabu">{{ it.nickname }}</span>
                        <span v-if="it.isEarlyWarn == '1'" style="margin-left:0" class="comment-box-yujing">已预警</span>
                      </div>

                      <span class="main-count-time">{{ it.commentTime }}</span>
                    </div>
                  </div>
                  <div class="main-count-text-box">
                    <p class="main-count-text">{{ it.content }}</p>
                    <div class="main-count-text-option">
                      <span @click="myCopy(it)" class="main-count-copy">
                        <img src="../../assets/img/views/fuzhi.png" alt />
                        <span style="margin-left: 4px">复制</span>
                      </span>
                      <div @click="quickCopy(it)">
                        <span style="margin:0;color:black;font-size:16px" class="el-icon-link"></span>
                        复制链接和标题
                      </div>
                      <span v-if="!rightview.pmonitorId" @click="deletes([it.dataId, it.monitorId], 4)"
                        class="main-count-copy" style="margin-left:40px">
                        <img src="../../assets/img/views/monitoringScheme/shanchu3.png" alt />
                        <span style="margin-left: 4px">删除</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="analyse-container" style="height:200px;" v-else>
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
        <div class="block">
          <span class="total">
            共{{ tablePage.total }}条, 当前显示第{{
              tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
            }}-{{
  tablePage.page * tablePage.pageSize > tablePage.total
  ? tablePage.total
  : tablePage.page * tablePage.pageSize
}}条
          </span>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="tablePage.page" :page-sizes="tablePage.pageSizes" :page-size="tablePage.pageSize"
            layout="sizes, prev, pager, next,jumper" :total="tablePage.total"></el-pagination>
        </div>
      </div>
      <div class="analyse-container" style="height:610px;" v-else>
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <div class="analyse-container analyse-container-box" v-else>
      <div class="analyse-container-img">
        <img src="../../assets/img/views/analyse-img.png" />
        <p>暂无数据</p>
      </div>
    </div>
    <!-- 新增监测方案 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :before-close="handleClose"
      :close-on-click-modal="false" :close-on-press-escape="false" width="700px">
      <div class="dialogcenter">
        <ul class="dialogcenter-top">
          <li>
            <div class="dialogcenter-top-left">上级方案</div>
            <el-select style="width: 100%" v-model="add.parentId" clearable placeholder="请选择方案" :disabled="disabled"
              @change="topChange" @clear="Disablebutton = false">
              <el-option v-for="item in options" :key="item.monitorId" :label="item.projectName"
                :value="item.monitorId"></el-option>
            </el-select>
          </li>
          <li style="width: 48%">
            <div class="dialogcenter-top-left">
              <span class="redtext">*</span>方案类型
            </div>
            <el-radio-group :disabled="jectTypeFlag" v-model="add.projectType" @change="selectRadio(add.projectType)">
              <el-radio label="0" v-if="$store.state.user.scope[0]">境内</el-radio>
              <el-radio label="1" v-if="$store.state.user.scope[1]">境外</el-radio>
              <el-radio label="2" v-if="$store.state.user.scope[2]">跟评</el-radio>
            </el-radio-group>
          </li>
          <li>
            <div class="dialogcenter-top-left">
              <span class="redtext">*</span> 方案名称
            </div>
            <el-input style="width: 100%" v-model="add.projectName" clearable placeholder="请输入内容"></el-input>
          </li>
          <li>
            <div class="dialogcenter-top-left">
              <span class="redtext">*</span>监测方式
            </div>
            <el-radio-group :disabled="jectTypeFlag" v-model="add.setType" @change="clearType(add.setType)">
              <el-radio :label="0">设置关键字</el-radio>
              <el-radio :disabled="add.projectType == '2'" :label="1">设置定向监测</el-radio>
            </el-radio-group>
          </li>
        </ul>
        <ul v-if="add.setType == 0" class="dialogcenter-content">
          <li>
            <div class="dialogcenter-content-left">
              <span class="redtext">*</span>
              预警方式
            </div>
            <el-radio v-model="add.warnWay" label="0" @change="selectChange">不预警</el-radio>
            <el-radio v-model="add.warnWay" label="2" @change="selectChange" v-if="add.projectType == 0">自动预警</el-radio>
            <el-radio v-model="add.warnWay" label="1" @change="selectChange">自定义预警</el-radio>
          </li>
          <!-- 自动预警 -->
          <div v-if="add.warnWay == 2" class="tonality-content">
            <div class="dialogcenter-content-left">
              舆情调性
            </div>
            <div style="display: flex">
              <el-checkbox v-model="tonalityCheckAll1" @change="handleCheckAllTonality">
                全部</el-checkbox>
              <el-checkbox-group v-model="tonality" @change="handleCheckedTonality">
                <el-checkbox v-for="x in tonalityList" :label="x.value" :key="x.value">{{ x.name }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <!-- 自定义预警 -->
          <div v-if="add.warnWay == 1" class="tonality-content">
            <div class="dialogcenter-content-left" v-if="add.projectType == 0">
              舆情调性
            </div>
            <div style="display: flex" v-if="add.projectType == 0">
              <el-checkbox v-model="tonalityCheckAll1" @change="handleCheckAllTonality">
                全部</el-checkbox>
              <el-checkbox-group v-model="tonality" @change="handleCheckedTonality">
                <el-checkbox v-for="x in tonalityList" :label="x.value" :key="x.value">{{ x.name }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="dialogcenter-content-left">
              预警条件
            </div>
            <div style="display: flex;margin-bottom: 16px;">
              <div :class="{ 'tonality-tab': true, 'active': tonalityTab == '预警账号' }" @click="tonalityTabActive('预警账号')">
                预警账号</div>
              <div :class="{ 'tonality-tab': true, 'active': tonalityTab == '预警网站' }" @click="tonalityTabActive('预警网站')">
                预警网站</div>
              <div :class="{ 'tonality-tab': true, 'active': tonalityTab == '预警词语' }" @click="tonalityTabActive('预警词语')">
                预警词语</div>
            </div>
            <el-input v-model="tonalityInput" @keyup.enter.native="tonalityInputYes"
              placeholder="可输入多个关键词逗号分隔，词与词之间为“或”关系，回车确认输入"></el-input>
            <div v-for="(x, i) in tonalityGroup" :key="i">
              <div class="tonalityInputList">
                <div class="tonalityInputList-name">{{ x.name }}：</div>
                <div class="tonalityInputList-tab">
                  <div v-for="(n, z) in x.data" :key="z" class="tonalityInputList-col">
                    <span>{{ n }}</span>
                    <img src="../../assets/img/views/cancel.png" @click="tonalityInputListDelete(x, z, i)">
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <li>
            <div class="dialogcenter-content-left">
              <span class="redtext" style="margin-right: 2px">*</span>数据范围
            </div>
            <div style="display: flex">
              <el-checkbox v-model="checkAll1" @change="handleCheckAllChange1">全部</el-checkbox>
              <el-checkbox-group v-model="checkedCities1" @change="handleCheckedCitiesChange1">
                <el-checkbox v-for="city in cities1" :label="city" :key="city" style="margin-bottom: 16px">{{ city
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </li>
          <li style="padding: 0 100px">
            <el-divider content-position="center">关键字设置</el-divider>
          </li>
          <li v-if="add.projectType == 0">
            <div class="dialogcenter-content-left">
              表达式模式
              <el-tooltip class="item" effect="dark" placement="right-end">
                <div slot="content">
                  <p>表达式</p>
                  <p>通过自定义公式设置要抓取的舆情信息。</p>
                  <p>如：(日本｜福岛)+(核污染｜核污水｜排海)</p>
                  <p>公式说明：</p>
                  <p>并：+</p>
                  <p>或：｜</p>
                  <p>优先：()</p>
                </div>
                <img src="../../assets/img/views/monitoringScheme/ts.png" />
              </el-tooltip>
            </div>
            <el-radio v-model="expressionOn" label="0" @input="expressionChange">不开启</el-radio>
            <el-radio v-model="expressionOn" label="1" @input="expressionChange">开启</el-radio>
          </li>
          <div v-if="expressionOn == '0'">
            <li>
              <div class="dialogcenter-content-left">
                <span class="redtext" style="margin-right: 2px">*</span>关键字组
              </div>
            </li>
            <li>
              <div class="dialogcenter-content-right">
                <div style="width: 576px; display: flex; margin-right: 12px">
                  <span class="redtext" style="margin-right: 2px">*</span>
                  <el-input style="width: 150px; margin-right: 16px" v-model="phrase" placeholder="词组名称"
                    clearable></el-input>
                  <span class="redtext" style="margin-right: 2px">*</span>
                  <el-input style="width: 180px; margin-right: 16px;" v-model="mainKeyword" placeholder="设置主关键字"
                    clearable></el-input>
                  <el-input style="width: 180px; margin-right: 16px;" v-model="deputyKeyword" clearable
                    placeholder="设置副关键字"></el-input>
                  <el-input style="width: 180px" v-model="deputyKeywordt" clearable placeholder="设置次关键字"></el-input>
                </div>
                <el-button @click="addCrux" type="text">添加</el-button>
              </div>
            </li>
            <li v-for="(val, inx) in add.keywordGroupList" :key="inx" style="display: flex">
              <div class="dialogcenter-content-div">
                <el-input class="dialogcenter-content-div-left"
                  :style="'width:' + (45 + add.keywordGroupList[inx].phrase.length * 15) + 'px'"
                  v-model="add.keywordGroupList[inx].phrase" @blur="contentCheck(add.keywordGroupList[inx].phrase)"
                  size="mini"></el-input>
                <div class="dialogcenter-content-div-right">
                  <div class="dialogcenter-content-div-right-box">
                    <div>主:</div>
                    <el-input contenteditable="true" class="dialogcenter-content-font" width="340px"
                      v-model="add.keywordGroupList[inx].mainKeyword"
                      @blur="contentCheck(add.keywordGroupList[inx].mainKeyword)" placeholder="主关键字不能为空！"
                      size="mini"></el-input>
                  </div>
                  <div class="dialogcenter-content-div-right-box">
                    <div>副:</div>
                    <el-input contenteditable="true" class="dialogcenter-content-font"
                      v-model="add.keywordGroupList[inx].deputyKeyword" size="mini"></el-input>
                  </div>
                  <div class="dialogcenter-content-div-right-box">
                    <div>次:</div>
                    <el-input contenteditable="true" class="dialogcenter-content-font"
                      v-model="add.keywordGroupList[inx].deputyKeywordt" size="mini"></el-input>
                  </div>
                </div>
              </div>
              <el-button @click="deleteCrux({ wordGroupId: val.wordGroupId, type: 2 }, inx)" style="color: #ea3342"
                type="text">删除</el-button>
            </li>
            <li>
              <div class="dialogcenter-content-left">忽略关键字</div>
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="add.ignoreKeyword"></el-input>
            </li>
            <li>
              <div class="dialogcenter-content-left">排除关键字</div>
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="add.excludeKeyword"></el-input>
            </li>
          </div>
          <div v-if="expressionOn == '1'" style="position: relative;padding-bottom: 30px;">
            <el-input type="textarea" :rows="10" placeholder="例：(日本｜福岛)+(核污染｜核污水｜排海)" v-model="expressionTextarea">
            </el-input>
            <div class="inspect-expression" @click="inspectExpression">检查表达式格式</div>
          </div>
        </ul>
        <ul v-if="add.setType == 1" class="dialogcenter-content">
          <li style="padding: 0 100px">
            <el-divider content-position="center">定向监测设置</el-divider>
          </li>
          <li style="width: 40%">
            <div class="dialogcenter-content-left">
              <span class="redtext">*</span> 预警方式
            </div>
            <el-radio v-model="add.warnWay" label="0" @change="selectChange">不预警</el-radio>
            <el-radio v-model="add.warnWay" label="1" @change="selectChange">自定义预警</el-radio>
          </li>
          <!-- 自定义预警 -->
          <div v-if="add.warnWay == 1" class="tonality-content">
            <div class="dialogcenter-content-left">
              预警条件
            </div>
            <div style="display: flex;margin-bottom: 16px;">
              <div :class="{ 'tonality-tab': true, 'active': tonalityTab == '预警账号' }" @click="tonalityTabActive('预警账号')">
                预警账号</div>
              <div :class="{ 'tonality-tab': true, 'active': tonalityTab == '预警网站' }" @click="tonalityTabActive('预警网站')">
                预警网站</div>
              <div :class="{ 'tonality-tab': true, 'active': tonalityTab == '预警词语' }" @click="tonalityTabActive('预警词语')">
                预警词语</div>
            </div>
            <el-input v-model="tonalityInput" @keyup.enter.native="tonalityInputYes"
              placeholder="可输入多个关键词逗号分隔，词与词之间为“或”关系，回车确认输入"></el-input>
            <div v-for="(x, i) in tonalityGroup" :key="i">
              <div class="tonalityInputList">
                <div class="tonalityInputList-name">{{ x.name }}：</div>
                <div class="tonalityInputList-tab">
                  <div v-for="(n, z) in x.data" :key="z" class="tonalityInputList-col">
                    <span>{{ n }}</span>
                    <img src="../../assets/img/views/cancel.png" @click="tonalityInputListDelete(x, z, i)">
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <li class="dialogcenter-content-flex">
            <span class="redtext">*</span>
            <el-select style="width: 200px" v-model="add.appname" clearable placeholder="请选择平台">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <span class="redtext">*</span>
            <el-input clearable style="width: 396px" v-model="add.monitorName" placeholder="请输入定向监测平台账号名称"></el-input>
          </li>
          <li class="dialogcenter-content-flex">
            <span class="redtext">*</span>
            <el-input clearable style="width: 516px" v-model="add.url" placeholder="账号主页网址1"></el-input>
            <el-button @click="addUrl" type="primary">添 加</el-button>
          </li>
          <li v-for="(val, inx) in add.directionalMonitorList" :key="inx + 'a'" class="dialogcenter-content-tab">
            <div class="dialogcenter-content-tab-div">
              <p style="max-width: 100px">{{ val.name }}</p>
              <p style="max-width: 100px">{{ val.monitorName }}</p>
              <p style="max-width: 250px">{{ val.url }}</p>
            </div>
            <img @click="deleteUrl({ dclId: val.dclId, type: 3 }, inx)"
              src="../../assets/img/views/monitoringScheme/XX.png" alt />
          </li>

        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain type="primary" @click="handleClose">取 消</el-button>
        <el-button plain type="primary" style="margin-left: 10px" @click="save('1')"
          :disabled="Disablebutton || saveDisabled">保存并运行</el-button>
        <el-button :disabled="saveDisabled" type="primary" @click="save('0')">保 存</el-button>
      </span>
    </el-dialog>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import {
  getMonitorList,
  getTopList,
  deleteDataById,
  updateRun,
  publicQuer,
  detailMonitor,
  addMonitor,
  editMonitor,
  removeMonitor,
  instartDirectionalMonitor,
  findAllData,
  findAllIos,
  verifyKeywordExpression
} from "../../api/monitoringScheme/index";
import deleteDialog from "../../components/deleteDialog";
import fackClickOutSide from "@/utils/fackClickOutSide";
export default {
  mixins: [fackClickOutSide],
  data() {
    return {
      //自定义预警 调性
      tonalityList: [{ value: "0", name: "正面" }, { value: "1", name: "中性" }, { value: "2", name: "负面" }],
      tonality: [],
      tonalityCheckAll1: false,
      tonalityTab: '预警账号',
      tonalityInput: "",
      tonalityGroup: [],
      radioValue: 2, // 精简1 || 列表2
      isEarlyWarn: false, //是否已预警
      // 保留搜索条件
      dataList: {},
      jectTypeFlag: false,
      noData: false,
      disabled: false, // 编辑框里如果方案有子方案 就为true  禁止选择
      submonitorId: "", // 编辑时获取当前id
      followdefaultId: sessionStorage.getItem("followdefaultId")
        ? sessionStorage.getItem("followdefaultId")
        : null, //默认id
      followdefaultType: "", // 默认类型  机构、公共
      Disablebutton: false, //禁止按钮
      loading: false, // 加载动画  数据列表
      timer: [], // 查询的时间
      dialogVisible: false,
      monitorData: [], //机构监测方案列表·
      publicmonitorData: [], // 公共监测方案列表
      dialogTitle: "新增监测方案", // 新增/编辑  弹出框标题
      monitorId: null, // 全局的方案ID
      options: [], // 上级方案
      options1: [
        // 预警方式
        {
          value: "0",
          label: "不预警",
        },
        {
          value: "1",
          label: "关键词预警",
        },
        {
          value: "2",
          label: "定向账号预警",
        },
        {
          value: "3",
          label: "定向网站预警",
        },
      ],
      options2: [],
      phrase: "", // 关键字
      deputyKeyword: "",
      deputyKeywordt: "",
      mainKeyword: "",
      editType: 1, // 1.增加  2.修改
      // 数据范围
      checkAll1: false,
      checkedCities1: [],
      cities1: [],
      textData: {}, // 数据列表
      rightview: {}, // 右边头部的信息展示  方案信息
      add: {
        operator:
          this.$store.state.user.name +
          "/" +
          (this.$store.state.user.phone ? this.$store.state.user.phone : "--"),
        isRunProject: "", // 运行方案
        operationType: 0, //操作类型
        organIds: [this.$store.state.user.organizationId], //机构id
        dataRange: "", //数据范围
        setType: 0, // 设置检测方式
        projectName: "", // 话题名称
        startTime: "", // 开始时间
        parentId: null, // 父级ID
        rank: null, //1 一级方案  2 二级方案
        appname: "0", //  快手/抖音等平台
        monitorName: "", //定向监测平台账号名称
        url: "", //定向监测地址
        earlyKeyword: "", //预警关键字
        ignoreKeyword: "", // 忽略关键字
        excludeKeyword: "", //排除关键字
        warnWay: "0", // 预警方式
        projectType: "2", //方案类型
        keywordGroupList: [], //关键字数组
        directionalMonitorList: [] //定向监测数组
      },
      seach: {
        value1: "", // 预警方式
        value2: "", // 监测方案模糊查询
        region: "originalTitle", // 查询数据列表  标题等
        value4: "", // 标题等查询
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        options1: [
          {
            value: "0",
            label: "不预警"
          },
          {
            value: "1",
            label: "预警"
          }
        ],
        options2: []
      }, // 跟评导航列表
      category: [
        {
          platformName: "全部"
        },
        {
          platformName: "网站"
        },
        {
          platformName: "纸媒"
        },
        {
          platformName: "电视"
        },
        {
          platformName: "微博"
        },
        {
          platformName: "微信公众号"
        },
        {
          platformName: "互动媒体"
        },
        {
          platformName: "移动客户端"
        },
        {
          platformName: "视频"
        }
      ],
      //数据列表 多选框
      checkAll6: false,
      checkedCities6: [],
      // 分页
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0
      },
      numb: "全部", // 跟评导航的名字
      allTotal: 0,
      saveDisabled: false,//禁用保存按钮
      saveSearch: {},
      suspend: false,
      //表达式
      expressionOn: "0",
      expressionTextarea: "",
      notify: null
    };
  },
  props: {
    jumpData: {
      type: Object
    }
  },
  components: {
    deleteDialog
  },
  watch: {
    checkedCities1(val) {
      this.checkAll1 = val.length === this.cities1.length;
    }
  },
  methods: {
    //表达式
    expressionChange() {
      this.expressionTextarea = "";
    },
    //表达式监测
    async inspectExpression() {
      let res = await verifyKeywordExpression({
      expression: this.expressionTextarea,
      });
      if (this.notify) {
        this.notify.close();
      }
      if (res.data.state == 200) {
        this.notify = this.$notify({
          title: "提示",
          message: "表达式规则匹配成功！",
          type: "success",
        });
      } else {
        this.notify = this.$notify({
          title: "提示",
          dangerouslyUseHTMLString: true,
          message:
            "<p>表达式规则匹配失败！</P><p>公式说明：</p><p>并：+</p><p>或：｜</p><p>优先：()</p><p>例：(日本｜福岛)+(核污染｜核污水｜排海)</p>",
          type: "warning",
          duration: 0,
        });
      }
    },
    /* 定向监测  自定义预警  开始 */
    tonalityInputListDelete(x, z, i) {
      x.data.splice(z, 1);
      if (x.data.length === 0) {
        this.tonalityGroup.splice(i, 1);
      }
    },
    tonalityInputYes() {
      this.tonalityInput = this.tonalityInput.replaceAll(" ", "");
      if (this.tonalityInput == "") {
        return false;
      }
      this.tonalityInput = this.tonalityInput.replaceAll("，", ",");
      let data = this.tonalityInput.split(",");
      let newData = [];
      data.forEach((x) => {
        if (x != "") {
          newData.push(x);
        }
      })
      let hansName = false;
      this.tonalityGroup.forEach((x) => {
        if (x.name == this.tonalityTab) {
          hansName = true;
          newData.forEach((n) => {
            x.data.push(n)
          });
        }
      });
      if (!hansName) {
        this.tonalityGroup.push({ name: this.tonalityTab, data: newData });
      }
      this.tonalityInput = "";
    },
    tonalityTabActive(value) {
      this.tonalityTab = value;
    },
    handleCheckAllTonality(val) {
      this.tonality = val ? ["0", "1", "2"] : [];
    },
    handleCheckedTonality(value) {
      let checkedCount = value.length;
      this.tonalityCheckAll1 = checkedCount === this.tonalityList.length;
    },
    selectChange() {
      this.tonality = [];
      this.tonalityCheckAll1 = false;
      this.tonalityTab = '预警账号';
      this.tonalityInput = "";
      this.tonalityGroup = [];
    },
    /*  定向监测  自定义预警 结束*/
    // 点击评论列表标题跳转原文地址
    routerOpen(val) {
      window.open(val);
    },
    // 点击媒体平台过滤数据 摔性能列表
    jump(inx) {
      this.numb = inx;
      this.tablePage.page = 1;
      if (this.monitorData.length == 0 && this.publicmonitorData.length == 0) {
        return false;
      } else {
        this.searchList();
        // this.getInof(this.rightview, 1);
      }
    },
    selectClose() {
      if (!this.seach.value1 && !this.seach.value2) {
        this.getLeftlist();
      }
    },
    inputClose() {
      if (!this.seach.value1 && !this.seach.value2) {
        this.getLeftlist();
      }
    },
    handleCheckedCitiesChange1(value) {
      let checkedCount = value.length;
      this.checkAll1 = checkedCount === this.cities1.length;
    },
    handleCheckAllChange6(val) {
      this.checkedCities6 = val ? this.textData : [];
    },
    handleCheckedCitiesChange6(value) {
      let checkedCount = value.length;
      this.checkAll6 = checkedCount === this.textData.length;
    },
    handleClick(tab, event) {
      this.jump(event.target.innerText);
    },
    async getListInof(type) {
      this.loading = true;
      const res = await getMonitorList({
        organId: this.$store.state.user.organizationId,
        projectType: "2"
      });
      let data = [];
      if (res.data.data) {
        data = res.data.data;
        this.monitorData = data.monitorScheme;
        if (this.monitorData.length != 0) {
          this.followdefaultId = sessionStorage.getItem("followdefaultId")
            ? sessionStorage.getItem("followdefaultId")
            : this.monitorData[0].monitorId;
        }
        this.publicmonitorData = data.publicMonitorScheme;
        if (
          this.publicmonitorData.length != 0 &&
          this.monitorData.length == 0
        ) {
          this.followdefaultId = sessionStorage.getItem("followdefaultId")
            ? sessionStorage.getItem("followdefaultId")
            : this.publicmonitorData[0].pmonitorId;
        }
        if (
          this.monitorData.length == 0 &&
          this.publicmonitorData.length == 0
        ) {
          this.noData = true;
          this.loading = false;
          return;
        }
      }

      // 获取上级方案   /因为涉及删除 每次方案列表变化了 上级方案也会受影响
      this.getTopList({
        organId: this.$store.state.user.organizationId,
        projectType: "2"
      });
      if (sessionStorage.getItem("followdefaultType") == "m") {
        //假设他刷新 还得保留他看的的东西哦
        this.monitorData.forEach(v => {
          if (v.monitorId == this.followdefaultId) {
            // 停留父方案
            this.getInof(v, type);
            this.getTopInof(v);
          } else {
            v.schemeVoList.forEach(e => {
              // 停留子方案
              if (e.monitorId == this.followdefaultId) {
                this.getInof(e, type);
                this.getTopInof(e);
              }
            });
          }
        });
        return;
      } else if (sessionStorage.getItem("followdefaultType") == "p") {
        this.publicmonitorData.forEach(v => {
          if (v.pmonitorId == this.followdefaultId) {
            // 停留父方案
            this.getInof(v, type);
            this.getTopInof(v);
          }
        });
        return;
      } else {
        this.followdefaultId = data.monitorScheme[0]
          ? data.monitorScheme[0].monitorId
          : data.publicMonitorScheme[0].pmonitorId;
        this.getInof(
          data.monitorScheme[0]
            ? data.monitorScheme[0]
            : data.publicMonitorScheme[0],
          type
        ); //默认进来 右边的数据需要第一个列表 monitorTD来调用
        this.getTopInof(
          data.monitorScheme.length > 0
            ? data.monitorScheme[0]
            : data.publicMonitorScheme.length > 0
              ? data.publicMonitorScheme[0]
              : ""
        ); //右边头部
        if (
          data.monitorScheme.length == 0 &&
          data.publicMonitorScheme.length == 0
        ) {
          this.rightview = {};
          this.textData = [];
        }
      }
    },
    // 只获取左边的列表  /新增 /编辑
    async getLeftlist() {
      let res;
      let data;
      if (this.seach.value1 != "" || this.seach.value2 != "") {
        let item = {
          organId: this.$store.state.user.organizationId,
          organType: this.$store.state.user.organType,
          projectType: "2"
        };
        item.warnWay = this.seach.value1;
        // 关键字预警/不预警
        if (this.seach.value2 != "") {
          item.projectName = this.seach.value2;
        }
        res = await findAllIos(item);
        data = res.data.data;
      } else {
        res = await getMonitorList({
          organId: this.$store.state.user.organizationId,
          projectType: "2"
        });
        data = res.data.data;
      }
      this.monitorData = data.monitorScheme;
      this.publicmonitorData = data.publicMonitorScheme;
      // 获取上级方案
      this.getTopList({
        organId: this.$store.state.user.organizationId,
        projectType: "2"
      });
    },
    // 右边的头部信息
    async getTopInof(item) {
      let res2;
      if (item.monitorId) {
        res2 = await detailMonitor({
          monitorId: item.monitorId,
          projectType: "2"
        });
      } else {
        res2 = await publicQuer({
          pmonitorId: item.pmonitorId,
          projectType: "2"
        });
      }
      if (res2.data.code == 200) {
        let data = res2.data.data;
        this.rightview = data;
      }
    },
    // 获取右边数据
    async getInof(item, type, obj) {
      this.loading = true;
      this.checkAll6 = false;
      let rank;
      if (item.rank) {
        rank = item.rank;
      } else if (item.ranks) {
        rank = item.ranks;
      }
      // 判断机构还是公共
      let data = {
        projectType: item.projectType,
        rank
      };
      if (item.monitorId) {
        data.monitorId = item.monitorId;
      } else {
        data.pmonitorId = item.pmonitorId;
      }
      this.rank = rank;
      // 判断跟评
      if (item.rank && this.numb !== "全部" && this.numb !== "") {
        data.mediaLink = this.numb;
        // this.tablePage.page = 1
      }
      if (obj) {
        this.dataList = obj;
        this.dataList.projectType = item.projectType;
        this.dataList.rank = rank;
        if (item.monitorId) {
          this.dataList.monitorId = item.monitorId;
        } else {
          this.dataList.pmonitorId = item.pmonitorId;
        }
        data = this.dataList;
      } else if (Object.values(this.dataList).length > 0) {
        data = this.dataList;
      }
      let url = `?pageNum=${this.tablePage.page}&pageSize=${this.tablePage.pageSize}`;
      this.saveSearch = JSON.parse(JSON.stringify(data));
      let res = await findAllData(data, url);
      if (res.data.data.code == 200) {
        this.textData = res.data.data.rows;
        this.tablePage.total = res.data.data.total;
        if (type == 1) {
          this.allTotal = res.data.data.total;
        }
      }
      if (this.textData.length == 0) {
        this.noData = true;
      }
      this.loading = false;
    },
    // 数据导出
    exportAllData() {
      if (this.suspend) {
        return;
      } else {
        this.suspend = true;
        setTimeout(() => {
          this.suspend = false;
        }, 1000);
      }
      let obj = {
        url: "api/monitor/monitor/exportAllData",
        method: "post",
        data: {}
      };
      obj.data = Object.assign(this.saveSearch, {
        projectType: this.rightview.projectType,
        rank: this.rightview.rank,
        projectName: this.rightview.projectName,
        userId: this.$store.state.user.userId,
        operator: this.$store.state.user.name
      });
      this.rightview.monitorId ? (obj.data.monitorId = this.rightview.monitorId) : (obj.data.pmonitorId = this.rightview.pmonitorId);
      this.$http(obj).then(() => {
        this.$parent.$parent.timeDreFresh();
      }).catch(err => {
        this.$message({
          message: err,
          type: "warning",
        });
      });
    },
    // 个别复制
    myCopy(str) {
      this.$publicFun.copyFun(str.copy.toString());
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.originalTitle + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
    },
    // 批量复制
    AllCopy() {
      if (this.checkedCities6.length > 0) {
        let test = [];
        this.checkedCities6.forEach(e => {
          test.push(e.copy);
        });
        this.$publicFun.copyFun(test.join(""));
      } else {
        this.$message({
          message: "请先选择需要复制的数据"
        });
      }
    },
    // 点击左边的方案列表切换右边的方案数据
    selectMonitor(item, type) {
      this.dataList = {};
      this.isEarlyWarn = false;
      this.numb = "全部";
      this.timer = [];
      this.seach.endTime = "";
      this.seach.startTime = "";
      // 标题
      this.seach.region = "originalTitle";
      this.seach.value4 = "";
      this.tablePage.page = 1;
      this.getTopInof(item);
      this.getInof(item, 1);
      if (type == 1) {
        this.followdefaultId = item.monitorId;
        sessionStorage.setItem("followdefaultType", "m");
        sessionStorage.setItem("followdefaultId", item.monitorId);
      } else {
        this.followdefaultId = item.pmonitorId;
        sessionStorage.setItem("followdefaultType", "p");
        sessionStorage.setItem("followdefaultId", item.pmonitorId);
      }
    },
    // 修改方案运行状态
    async updateRun(item) {
      let rank;
      if (item.rank) {
        rank = item.rank;
      } else if (item.ranks) {
        rank = item.ranks;
      }
      let data = {
        isRunProject: item.isRunProject == "0" ? "1" : "0",
        rank,
        monitorId: item.monitorId
      };
      let res = await updateRun(data);
      if (res.data.code == 200) {
        this.$message({
          message: "修改状态成功",
          type: "success"
        });
        // 在方案列表里 修改的是自己状态 就更新右侧头部信息和方案列表  修改的是其他方案的状态  就只更新方案列表
        if (this.rightview.monitorId == item.monitorId) {
          this.getLeftlist();
          this.getTopInof(this.rightview);
        } else {
          this.getLeftlist();
        }
      } else {
        this.$message({
          message: res.data.msg,
          type: "error"
        });
      }
    },
    // 获取上级方案
    async getTopList(data) {
      const res = await getTopList(data);
      if (res.data.code == 200) {
        this.options = res.data.data;
      }
    },
    // 点击 境内/境外/跟评 切换上级方案
    selectRadio(id) {
      this.checkAll1 = false;
      this.checkedCities1 = [];
      this.add.parentId = null; //将上级方案选择框变为空
      this.add.warnWay = "0";
      this.add.setType = 0;
      this.tonality = [];
      this.tonalityCheckAll1 = false;
      this.tonalityTab = '预警账号';
      this.tonalityInput = "";
      this.tonalityGroup = [];
      this.expressionOn = "0";
      this.expressionTextarea = "";
      if (this.add.projectType == 1) {
        this.cities1 = [
          "机构官网",
          "社交媒体",
          "社区论坛",
          "新闻网站",
          "资讯平台",
          "其他"
        ];
        this.options2 = [
          {
            value: "0",
            label: "推特"
          },
          {
            value: "1",
            label: "facebook"
          },
          {
            value: "2",
            label: "ins"
          }
        ];
        this.add.appname = "0";
      } else {
        this.cities1 = [
          "网站",
          "纸媒",
          "电视",
          "微博",
          "微信公众号",
          "移动客户端",
          "视频",
          "互动栏目"
        ];

        this.options2 = [
          // 定向监测平台
          {
            value: "0",
            label: "微博"
          },
          {
            value: "1",
            label: "微信"
          },
          {
            value: "2",
            label: "今日头条"
          }
        ];
        this.add.appname = "0";
      }
      let data = {
        organId: this.$store.state.user.organizationId,
        projectType: id
      };
      this.getTopList(data);
    },
    // 新增方案 /编辑方案/获取上级方案
    async subScheme(item) {
      this.saveDisabled = false;
      this.disabled = false;
      this.submonitorId = item.monitorId;
      this.dialogVisible = true;
      this.tonalityTab = "预警账号";
      if (item.type == "edit") {
        this.jectTypeFlag = true;
        this.monitorData.forEach(v => {
          if (item.monitorId == v.monitorId) {
            if (v.schemeVoList.length > 0) {
              this.disabled = true;
            }
          }
        });
        this.dialogTitle = "编辑监测方案";
        this.editType = 2; // 子级菜单
        const res = await detailMonitor({
          monitorId: item.monitorId,
          projectType: "2"
        });
        this.monitorId = item.monitorId;
        let data = res.data.data;
        if (res.data.msg == "操作成功") {
          if (data.keywordExpression && data.keywordExpression.trim() != "") {
            this.expressionOn = "1";
            this.expressionTextarea = data.keywordExpression;
          } else {
            this.expressionOn = "0";
            this.expressionTextarea = "";
          }
          this.add.setType = data.setType; // 设置关键字方式
          this.add.parentId = data.parentId;
          this.topChange();
          // 有父级id 就让它继承父级的选项
          this.ifdParintd(data);
          this.add.projectName = data.projectName; // 上级方案框
          this.add.warnWay = data.warnWay; // 关键字方式
          // this.add.earlyKeyword = data.earlyKeyword; //预警关键字
          this.add.ignoreKeyword = data.ignoreKeyword; // 忽略关键字
          this.add.excludeKeyword = data.excludeKeyword; // 排除关键字
          this.add.dataRange = data.dataRange; //数据范围
          this.add.startTime = data.startTime; // 开始时间爱
          this.add.keywordGroupList = data.keywordGroupList; //关键字组
          this.add.directionalMonitorList = data.directionalMonitorList; // 定向检测数组
          this.add.directionalMonitorList.forEach(e => {
            this.options2.forEach(i => {
              if (e.medieType == i.value) {
                e.name = i.label;
              }
            });
          });
          this.tonalityGroup = [];
          if (data.earlyKeyword != null && data.earlyKeyword != "") {
            this.tonalityGroup.push({ name: "预警词语", data: data.earlyKeyword.split(" ") });
          }
          if (data.earlyAuthor != null && data.earlyAuthor != "") {
            this.tonalityGroup.push({ name: "预警账号", data: data.earlyAuthor.split(",") });
          }
          if (data.earlyWebsiteList && data.earlyWebsiteList.length != 0) {
            let obj = [];
            data.earlyWebsiteList.forEach((x) => {
              obj.push(x.name);
            })
            this.tonalityGroup.push({ name: "预警网站", data: obj });
          }
          if (data.tonalStateSettingString != null && data.tonalStateSettingString != "") {
            this.tonality = data.tonalStateSettingString.split(",");
          }
        }
      } else {
        this.jectTypeFlag = false;
        this.editType = 1; // 父级菜单
        this.dialogTitle = "新增监测方案";
        let dataRange = [];
        this.options.forEach(v => {
          if (this.submonitorId == v.monitorId) {
            dataRange = v.dataRange;
          }
        });
        this.cities1 =
          dataRange && dataRange.length > 0
            ? dataRange.split(",")
            : this.cities1;
        if (item.monitorId) {
          this.add.parentId = item.monitorId;
          this.topChange();
        }
      }
    },
    ifdParintd(data) {
      if (this.add.projectType == 1) {
        this.cities1 = [
          "机构官网",
          "社交媒体",
          "社区论坛",
          "新闻网站",
          "资讯平台",
          "其他"
        ];
      } else {
        this.cities1 = [
          "网站",
          "纸媒",
          "电视",
          "微博",
          "微信公众号",
          "移动客户端",
          "视频",
          "互动栏目"
        ];
      }
      if (data.parentId) {
        this.disabled = true;
        let dataRange = [];
        this.options.forEach(v => {
          if (data.parentId == v.monitorId) {
            dataRange = v.dataRange;
          }
        });
        //  他要子方案 继承的父方案的数据范围 我只能这样改变数据格式
        this.cities1 = dataRange ? dataRange.split(",") : this.cities1;
        this.checkedCities1 = data.dataRange
          ? data.dataRange.split(",")
          : this.cities1; //数据范围数组
        if (this.checkedCities1.length == this.cities1.length) {
          this.checkAll1 = true;
        }
      } else {
        this.disabled = true;
        this.checkedCities1 = data.dataRange
          ? data.dataRange.split(",")
          : this.cities1; //数据范围数组
        if (this.checkedCities1.length == this.cities1.length) {
          this.checkAll1 = true;
        }
      }
    },
    // 上级方案选择
    topChange() {
      this.monitorData.forEach(v => {
        if (this.add.parentId == v.monitorId) {
          if (v.isRunProject == "0") {
            this.Disablebutton = true;
          } else {
            this.Disablebutton = false;
          }
        }
      });
    },
    // 弹框里面获取全部范围数组
    handleCheckAllChange1(val) {
      this.checkedCities1 = val ? this.cities1 : [];
    },
    // 切换设置 清空数据
    clearType(type) {
      if (type == 0) {
        this.add.monitorName = "";
        this.add.url = "";
        this.add.directionalMonitorList = [];
      } else {
        this.add.earlyKeyword = ""; //预警关键字
        this.add.dataRange = ""; //数据范围
        this.checkedCities1 = []; //数据范围数组清空
        this.add.keywordGroupList = []; //词组
        this.add.ignoreKeyword = ""; // 忽略关键字
        this.add.excludeKeyword = ""; //排除关键字
        this.checkAll1 = false; // 数据范围全部 不勾选
      }
    },
    //添加关键字
    async addCrux() {
      this.phrase = this.phrase.trim();
      this.deputyKeyword = this.deputyKeyword.trim();
      this.mainKeyword = this.mainKeyword.trim();
      this.deputyKeywordt = this.deputyKeywordt.trim();
      if (this.phrase != "" && this.mainKeyword != "") {
        this.add.keywordGroupList.push({
          phrase: this.phrase,
          deputyKeyword: this.deputyKeyword,
          mainKeyword: this.mainKeyword,
          deputyKeywordt: this.deputyKeywordt,
          montiorType: "0" //检测类型  是客户端 因此是 0  0机构方案 1公共方案
        });
        this.phrase = ""; // 关键字
        this.deputyKeyword = ""; //主关键字
        this.mainKeyword = ""; // 副关键字
        this.deputyKeywordt = "";
      } else if (this.phrase == "" || this.mainKeyword == "") {
        this.$message({
          message: "请填写完全后再添加",
          type: "warning"
        });
      }
    },
    //添加url
    async addUrl() {
      if (
        this.add.appname != "" &&
        this.add.monitorName != "" &&
        this.add.url != ""
      ) {
        var appname = "";
        this.options2.forEach(i => {
          if (i.value == this.add.appname) {
            appname = i.label;
          }
        });
        this.add.directionalMonitorList.push({
          name: appname,
          medieType: this.add.appname,
          monitorName: this.add.monitorName,
          url: this.add.url
        });
        let data = {
          monitorId: this.monitorId,
          medieType: this.add.appname,
          monitorName: this.add.monitorName,
          url: this.add.url,
          montiorType: "0" //检测类型  是客户端 因此是 0  0机构方案 1公共方案
        };
        await instartDirectionalMonitor(data);
        this.add.monitorName = "";
        this.add.url = "";
      } else {
        this.$message({
          message: "请填写完全后再添加",
          type: "warning"
        });
      }
    },
    //删除url
    deleteUrl(data, inx) {
      this.deletes(data.dclId, data.type, inx);
    },
    // 删除监测方案弹框  type 1.表示方案  2，表示关键字组  3.表示定向检测 4.表示监测数据列表
    deletes(Id, type, inx) {
      let item = {
        message: "监测方案",
        Id,
        type,
        inx
      };
      switch (item.type) {
        case 2:
          item.message = "此条关键字组";
          break;
        case 3:
          item.message = "此条定向监测设置";
          break;
        case 4:
          item.message = "监测数据";
          break;
        case 5:
          item.message = "多个监测数据";
          break;
      }
      this.$refs.openDialog.openDialog(item);
    },
    // 删除监测方案
    async deleteFn(item) {
      let data = {};
      let res;
      if (item.type == 1 || item.type == 6) {
        sessionStorage.removeItem("followdefaultType");
        //1.表示方案 6.表示从右边头部删除
        data = {
          monitorId: item.Id,
          organId: this.$store.state.user.organizationId
        };
        res = await removeMonitor(data);
      } else if (item.type == 3) {
        // 3.表示定向检测
        data = {
          dclId: item.Id,
          monitorId: item.Id[1] + ""
        };
        this.add.directionalMonitorList.splice(item.inx, 1);
        res = await removeMonitor(data);
      } else if (item.type == 4) {
        // 4.表示监测数据列表
        data = {
          dataId: item.Id[0],
          projectType: "2",
          rank: this.rank,
          monitorId: item.Id[1] + ""
        };
        if (this.textData.length == 1) {
          this.tablePage.page = this.tablePage.page - 1;
        }
        res = await deleteDataById(data);
      } else if (item.type == 5) {
        if (this.checkedCities6.length > 0) {
          let dataIds = [];
          this.checkedCities6.forEach(e => {
            dataIds.push(e.dataId);
          });
          // 5.批量删除
          data = {
            dataIds,
            projectType: "2",
            rank: this.rank,
            monitorId: this.checkedCities6[0].monitorId + ""
          };
          this.tablePage.page = 1;
          res = await deleteDataById(data);
        } else {
          this.$message({
            message: "请先选择需要删除的监测数据"
          });
          return;
        }
      }
      if (res.data.code == 200 || res.data.data == 1) {
        this.$message({
          message: "删除成功",
          type: "success"
        });
        if (item.type == 5 || item.type == 4) {
          setTimeout(() => {
            this.getInof(this.rightview, 1);
          }, 1000);
        } else if (item.type == 6) {
          setTimeout(() => {
            this.getListInof(1);
          }, 1000);
        } else if (item.type == 1) {
          // 在方案列表里 删的是自己 就调用所有接口  删的是别的方案  就只调用方案列表
          if (this.rightview.monitorId == item.Id) {
            setTimeout(() => {
              this.getListInof(1);
            }, 1000);
          } else {
            setTimeout(() => {
              this.getLeftlist();
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.getLeftlist();
          }, 1000);
        }
      } else {
        this.$message.error(res.data.msg);
      }
    },
    //删除关键字
    deleteCrux(data, inx) {
      this.add.keywordGroupList.splice(inx, 1);
    },
    // 重置
    resetting() {
      this.dataList = {};
      this.numb = "全部";
      this.isEarlyWarn = false;
      // 时间
      this.timer = [];
      this.seach.endTime = "";
      this.seach.startTime = "";
      // 标题
      this.seach.region = "originalTitle";
      this.seach.value4 = "";
      // 页码
      this.tablePage.page = 1;
      this.checkAll6 = false;
      this.getInof(this.rightview, 1);
    },
    //  保存/保存并运行
    async save(type) {
      // 公共判断
      if (this.add.projectName.trim() == "") {
        this.$message.error("请填写方案名称！");
        return;
      }
      const data = {};
      data.organIds = [this.$store.state.user.organizationId];
      data.isRunProject = type;
      data.operator = this.$store.state.user.name + "/" + (this.$store.state.user.phone ? this.$store.state.user.phone : "--");
      data.expressionOn = this.expressionOn;
      data.projectName = this.add.projectName.trim(); //做前后去空格
      data.operationType = 0;
      if (this.checkAll1) {
        data.dataRange = null;
      } else {
        data.dataRange = this.checkedCities1.join(","); //数组变成字符串
      }
      if (this.add.parentId) {
        data.rank = 2;
      } else {
        data.rank = 1;
      }
      data.warnWay = this.add.warnWay;
      data.projectType = this.add.projectType;
      data.setType = this.add.setType;
      // 预警判断
      if (this.add.warnWay != 0) {
        // 预警调性
        if (this.tonalityCheckAll1) {
          data.tonalStateSetting = [3];
        } else {
          data.tonalStateSetting = this.tonality;
        }

        //预警条件: 预警账号   预警网站   预警词语
        this.tonalityGroup.forEach((x) => {
          if (x.name == "预警账号") {
            let arr = [];
            x.data.forEach((item) => {
              arr.push(item);
            });
            data.earlyAuthor = arr.join(",");
          }
          if (x.name == "预警网站") {
            let arr = [];
            x.data.forEach((item) => {
              arr.push({ name: item, url: "", monitorId: null, type: null });
            });
            data.earlyWebsiteList = arr;
          }
          if (x.name == "预警词语") {
            let arr = [];
            x.data.forEach((item) => {
              arr.push(item);
            });
            data.earlyKeyword = arr.join(" ");
          }
        })
      }
      // 区分关键字/定向
      if (this.add.setType == 0) {
        // 关键字设置又分 关键字||表达式
        if (this.checkedCities1.length < 1) {
          this.$message.error("请选择数据范围！");
          return;
        }
        if (this.add.warnWay != 0 && this.tonality.length == 0 && this.add.projectType == 0) {
          this.$message({
            message: "请选择舆情调性！",
            type: "error",
          });
          return false;
        }
        if (this.expressionOn == 0) {
          if (this.add.keywordGroupList.length == 0) {
            this.$message.warning("请输入关键字组！");
            return;
          } else {
            // 关键字修改校验
            for (let i = 0; i < this.add.keywordGroupList.length; i++) {
              if (this.add.keywordGroupList[i].phrase == false || this.add.keywordGroupList[i].mainKeyword == false) {
                this.$message.error("请将词组名称与主关键字填写完整");
                return;
              }
            }
            for (let i = 0; i < this.add.keywordGroupList.length; i++) {
              if (this.add.keywordGroupList[i].deputyKeyword) {
                if (
                  this.add.ignoreKeyword == this.add.keywordGroupList[i].deputyKeyword ||
                  this.add.excludeKeyword == this.add.keywordGroupList[i].deputyKeyword
                ) {
                  this.$message.warning("忽略关键字或排除关键字不能和关键字组一致！");
                  return false;
                }
              }
              if (this.add.keywordGroupList[i].mainKeyword) {
                if (
                  this.add.ignoreKeyword == this.add.keywordGroupList[i].mainKeyword ||
                  this.add.excludeKeyword == this.add.keywordGroupList[i].mainKeyword
                ) {
                  this.$message.warning("忽略关键字或排除关键字不能和关键字组一致！");
                  return false;
                }
              }
              if (this.add.keywordGroupList[i].deputyKeywordt) {
                if (
                  this.add.ignoreKeyword == this.add.keywordGroupList[i].deputyKeywordt ||
                  this.add.excludeKeyword == this.add.keywordGroupList[i].deputyKeywordt
                ) {
                  this.$message.warning("忽略关键字或排除关键字不能和关键字组一致！");
                  return false;
                }
              }
            }

          }
          data.keywordGroupList = this.add.keywordGroupList;
          data.ignoreKeyword = this.add.ignoreKeyword.trim();
          data.excludeKeyword = this.add.excludeKeyword.trim();
        } else {
          if (this.expressionTextarea.trim() == "") {
            this.$message({
              message: "请填写表达式！",
              type: "error",
            });
            return false;
          } else {
            let res = await verifyKeywordExpression({ expression: this.expressionTextarea });
            if (this.notify) {
              this.notify.close();
            }
            if (res.data.state == 200) {
              data.keywordExpression = this.expressionTextarea.trim();
            } else {
              this.notify = this.$notify({
                title: "提示",
                dangerouslyUseHTMLString: true,
                message:
                  "<p>表达式规则匹配失败！</P><p>公式说明：</p><p>并：+</p><p>或：｜</p><p>优先：()</p><p>例：(日本｜福岛)+(核污染｜核污水｜排海)</p>",
                type: "warning",
                duration: 0,
              });
              return false;
            }
          }
        }
      } else if (this.add.setType == 1) {
        if (this.add.directionalMonitorList.length < 1) {
          this.$message.error("请添加定向监测设置！");
          return;
        }
        data.directionalMonitorList = this.add.directionalMonitorList;
        data.directionalMonitorList.forEach(item => {
          if (item.name == "移动客户端(今日头条)") {
            item.name = "今日头条";
          }
          if (item.name == "微信公众号") {
            item.name = "微信";
          }
        });
      }
      this.saveDisabled = true;
      if (this.editType == 1) {
        //增加
        const res = await addMonitor(data);
        if (res.data.msg == "操作成功") {
          this.$message({
            message: "新增方案成功",
            type: "success",
          });
          this.getLeftlist();
          this.getListInof();
        }
      } else {
        //修改
        if (this.add.parentId == this.submonitorId) {
          //不能把自己设为自己的上级
          this.$message.error("不能将上级方案设为自己");
          return;
        }
        data.monitorId = this.monitorId;
        if (data.warnWay === "0") {
          data.earlyKeyword = "";
        }
        const res = await editMonitor(data);
        if (res.data.msg == "操作成功") {
          this.$message({
            message: "修改方案成功",
            type: "success",
          });
          this.getLeftlist();
          this.getTopInof(this.rightview);
        }
      }
      this.dialogVisible = false;
      setTimeout(() => {
        this.saveDisabled = false;
      }, 500);
      this.handleClose();
    },
    // 分页
    handleSizeChange(val) {
      this.checkAll6 = false;
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.getInof(this.rightview);
      window.scrollTo(0, 0);
    },
    // 点击页码跳转
    handleCurrentChange(val) {
      this.checkAll6 = false;
      this.tablePage.page = val;
      this.getInof(this.rightview);
      window.scrollTo(0, 100);
    },
    // 关闭弹框
    handleClose() {
      this.add.warnWay = "0";
      if (this.add.projectType == 1) {
        this.cities1 = [
          "机构官网",
          "社交媒体",
          "社区论坛",
          "新闻网站",
          "资讯平台",
          "其他"
        ];
      } else {
        this.cities1 = [
          "网站",
          "纸媒",
          "电视",
          "微博",
          "微信公众号",
          "移动客户端",
          "视频",
          "互动栏目"
        ];
      }
      this.checkAll1 = false;
      this.add.parentId = null;
      this.dialogVisible = false; //关闭弹框了
      this.checkedCities1 = []; //数据范围数组清空
      this.add.directionalMonitorList = []; //定向监测方案
      this.add.projectName = ""; // 上级方案框
      // this.add.warnWay = ""; // 预警方式
      this.add.earlyKeyword = ""; //预警关键字
      this.add.ignoreKeyword = ""; // 忽略关键字
      this.add.excludeKeyword = ""; // 排除关键字
      this.add.dataRange = ""; //数据范围
      this.add.setType = 0; // 设置关键字方式
      this.add.startTime = ""; // 开始时间
      this.add.keywordGroupList = []; //关键字组
      this.Disablebutton = false; // 禁止按钮放开
    },
    // 筛选条件
    // async selectConditon() {
    //   let res = await selectConditon();
    //   this.category = res.data.data;
    //   this.numb = this.category[0].platformName;
    // },
    // 监测数据 搜索按钮
    searchList() {
      if (this.timer[0] && this.timer[1] && this.timer[0] > this.timer[1]) {
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if (!this.timer[0] || !this.timer[1]) {
        this.timer = [];
      }
      let data = {};
      if (this.isEarlyWarn) {
        data.isEarlyWarn = 1;
      }
      if (this.numb !== "全部" && this.numb !== "") {
        data.mediaLink = this.numb;
        // this.tablePage.page = 1
      }
      //标题 正文  作者 region
      if (this.seach.value4 != "") {
        data[this.seach.region] = this.seach.value4;
      }
      if (this.timer[0] && this.timer[1] && this.timer[0] <= this.timer[1]) {
        data.startTime = this.seach.startTime;
        data.endTime = this.seach.endTime;
      } else {
        this.seach.startTime = "";
        this.seach.endTime = "";
      }
      this.tablePage.page = 1;
      this.checkAll6 = false;
      if ((!this.timer[0] || !this.timer[1]) && !this.seach.value4) {
        this.getInof(this.rightview, 1, data);
      } else {
        this.getInof(this.rightview, 2, data);
      }
    },
    // 选择时间
    timeChange(val, type) {
      if (type == 1 && val) {
        this.seach.startTime = `${val} 00:00:00`;
      }
      if (type == 2 && val) {
        this.seach.endTime = `${val} 23:59:59`;
      }
    },
    // 搜索方案列表  预警/ 不预警
    async searchScheme() {
      //  模糊查询字段
      if (this.seach.value1 != "") {
        let data = {
          organId: this.$store.state.user.organizationId,
          organType: this.$store.state.user.organType
        };
        data.warnWay = this.seach.value1;
        // 关键字预警/不预警
        if (this.seach.value2 != "") {
          data.projectName = this.seach.value2;
        }
        let res = await findAllIos(data);
        this.monitorData = res.data.data.monitorScheme;
        this.publicmonitorData = res.data.data.publicMonitorScheme;
      } else {
        this.getLeftlist();
      }
    },
    contentCheck(val) {//关键字内容验证
      if (val) {
        event.target.style.borderColor = "";
      } else {
        event.target.style.borderColor = "red";
      }
    }
  },
  created() {
    this.getListInof(1);
    this.selectRadio(this.add.projectType);
    if (Object.keys(this.jumpData).length != 0) {
      let type = null;
      if (this.jumpData.monitorType == 0) {
        type = 1;
      } else if (this.jumpData.monitorType == 1) {
        type = 2;
      }
      this.selectMonitor(this.jumpData, type);
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  .left {
    width: 26%;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
    min-height: 750px;
    position: relative;

    .seach {
      display: flex;
    }

    .content {
      padding: 16px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      margin-bottom: 16px;
      cursor: pointer;

      .content-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content-top-left {
          max-width: 290px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 22px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }

        .content-top-right {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      .content-content {
        display: flex;
        align-items: center;
        margin-top: 24px;

        img {
          margin-right: 2px;
        }

        .content-content-delete {
          font-size: 14px;
          color: #ea3342;
          display: flex;
          align-items: center;
          margin-right: 40px;
          cursor: pointer;
        }

        .content-content-edit {
          font-size: 14px;
          color: #2e59ec;
          display: flex;
          align-items: center;
          margin-right: 40px;
          cursor: pointer;
        }

        .content-content-function {
          font-size: 14px;
          color: #02bc7c;
          display: flex;
          align-items: center;
          margin-right: 40px;
          cursor: pointer;
        }

        .content-content-stop {
          font-size: 14px;
          color: #ff9a16;
          display: flex;
          align-items: center;
          margin-right: 40px;
          cursor: pointer;
        }
      }

      .content-bottom {
        width: 100%;
        background: #f6f7fa;
        border-radius: 4px;
        padding: 16px;
        margin-top: 10px;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
      }

      .content-bottom:hover {
        box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.14);
      }

      .contentshad2 {
        box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.08);
      }
    }

    .content:hover {
      box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
    }

    .contentshad {
      box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
    }

    .division {
      padding: 0 60px;

      ::v-deep .el-divider__text {
        font-size: 16px;
        color: #999999;
      }
    }

    .public {
      width: 100%;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      cursor: pointer;

      div {
        font-size: 16px;
        color: #333333;
        padding-left: 16px;
      }
    }

    .public:hover {
      box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
    }

    .contentshad3 {
      box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.08);
    }

    ::v-deep .el-button--primary {
      width: 100%;
      height: 36px;
      background: #13a8fd;
      border-radius: 4px;
      margin: 24px 0;
    }
  }

  .right {
    width: 73%;
    background: #fff;

    .right-top {
      width: 100%;
      height: 121px;
      background-image: url("../../assets/img/views/bag.png");
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 24px;

      .right-top-top {
        display: flex;
        justify-content: space-between;

        .right-top-top-left {
          font-size: 26px;
          font-weight: bold;
          color: #ffffff;

          span {
            font-size: 14px;
          }
        }

        .right-top-top-right {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;

          div {
            margin-left: 32px;
            display: flex;
            align-items: center;

            img {
              margin-right: 3px;
            }
          }
        }
      }

      .right-top-bottom {
        margin-top: 16px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .right-contnet {
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px 0;

      .right-contnet-checkbox {
        padding: 0 24px;

        // border-bottom: 1px solid #e4e6ec;
        ul {
          li {
            float: left;
            margin-right: 80px;
            padding-bottom: 16px;
          }

          .Selectli {
            color: #2e59ec;
            border-bottom: 2px solid #2e59ec;
            cursor: pointer;
            font-size: 16px;
          }

          .NoSelect {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            cursor: pointer;
          }
        }
      }

      .right-contnet-seach {
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        border-bottom: 1px solid #e4e6ec;

        .right-contnet-seach-left {
          display: flex;
          align-items: center;

          .el_font {
            margin-left: 32px;
            margin-right: 12px;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
        }

        .right-contnet-seach-right {
          ::v-deep .el-button {
            width: 60px;
            height: 32px;
            border-radius: 4px;
            padding: 0;
          }
        }
      }

      .right-contnet-content {
        min-height: 200px;

        .delete {
          padding: 8px 15px;
          font-size: 14px;
          width: 88px;
          border: 1px solid #f7969e;
          color: #ea3342;
        }

        .copy {
          font-size: 14px;
          padding: 8px 15px;
          width: 88px;
          border: 1px solid #9cb0f6;
          color: #2e59ec;
        }

        .delete:focus,
        .delete:hover {
          background-color: #f7969e;
        }

        .right-contnet-content-div {
          width: 100%;
          padding: 24px 24px;
          border-bottom: 1px solid #e4e6ec;

          .right-contnet-content-div-title {
            display: flex;
            justify-content: space-between;

            .right-contnet-content-div-title-left {
              display: flex;
              align-items: center;

              .nickname {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
              }

              .content {
                font-size: 16px;
                color: #333333;
              }

              .right-contnet-content-div-title-left-span {
                margin: 0 0 0 8px;
                padding: 0px 8px;
                background: #36cbcb;
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                line-height: 24px;
                text-align: center;
              }

              h1 {
                font-size: 18px;
                font-weight: bold;
                color: #333333;
                margin-left: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 800px;
              }
            }

            .right-contnet-content-div-title-right {
              display: flex;
              align-items: center;
            }
          }

          .right-contnet-content-div-content {
            width: 100%;
            font-size: 14px;
            color: #444;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin: 16px 0;
            margin-left: 32px;
            line-height: 20px;

            .originalTitle {
              font-size: 16px;
              color: #888;
              margin-right: 8px;
            }

            .originalTitles {
              font-size: 16px;
              color: #333333;
            }

            .originalTitles:hover {
              color: #2e59ec;
              cursor: pointer;
            }
          }

          .right-contnet-content-div-tag {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 32px;

            div {
              font-size: 14px;
              color: #888;
            }

            .right-contnet-content-div-operation-right {
              display: flex;
            }

            .options {
              display: flex;
              align-items: center;
              cursor: pointer;

              span {
                font-size: 14px;
                color: #333333;
              }
            }
          }
        }
      }

      .block {
        position: relative;
      }

      .block-count {
        position: absolute;
        top: 7px;
        font-size: 14px;
        color: #333333;
        left: 0;
      }

      .block .total {
        position: absolute;
        bottom: 5%;
        left: 8%;
        z-index: 11;
        font-size: 14px;
        display: inline-block;
        line-height: 32px;
        color: #999999;
      }
    }
  }

  .dialogcenter {
    .dialogcenter-top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      li {
        width: 48%;
        margin-bottom: 24px;

        .dialogcenter-top-left {
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
    }

    .dialogcenter-content {
      .dialogcenter-content-msg {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        background: #fff8ef;
        border-radius: 4px;
        border: 1px solid #ffae45;
        font-size: 14px;
        color: #ffae45;

        img {
          margin: 0 8px;
        }
      }

      .dialogcenter-content-tab {
        width: 100%;
        height: 40px;
        background: #f5f6fa;
        padding: 10px 16px;
        display: flex;
        align-items: center;

        .dialogcenter-content-tab-div {
          width: 588px;
          display: flex;
          align-items: center;

          p {
            margin-right: 40px;
            font-size: 14px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .dialogcenter-content-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      li {
        width: 100%;
        margin-bottom: 24px;

        ::v-deep .el-checkbox {
          margin-right: 30px;
        }

        ::v-deep .el-divider__text {
          font-size: 16px;
          color: #999999;
        }

        .dialogcenter-content-left {
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }

        .dialogcenter-content-div {
          width: 576px;
          margin-right: 12px;
          background: #f5f6fa;
          border-radius: 4px;
          display: flex;
          padding: 8px 16px;

          .dialogcenter-content-div-left {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            min-width: 60px;
            max-width: 200px;
          }

          .dialogcenter-content-div-right {
            margin-left: 40px;
            font-size: 14px;
            color: #333333;

            .dialogcenter-content-div-right-box {
              display: flex;
              width: 100%;
              line-height: 28px;

              span:nth-child(1) {
                height: 100%;
              }
            }
          }
        }

        .dialogcenter-content-right {
          display: flex;
        }
      }
    }
  }

  .redtext {
    color: red;
    margin: 0 3px 0 3px;
  }
}

.left-monitoring {
  min-height: 600px;
  padding: 10px;
}

.left-monitoring::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}

.left-monitoring::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: rgba(0, 0, 0, 0.2);
}

.left-monitoring::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.analyse-container {
  width: 100%;
  height: 220px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.xiangqing-main-left-two {
  width: 100%;
  background: #f7f7f7;
  padding: 24px;
}

.main-count-text-option {
  display: flex;
}

.main-count-copy {
  color: #333333;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.main-count-text-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-count-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.main-count-fabu {
  margin-right: 16px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.main-count-time {
  font-size: 14px;
  color: #999999;
}

.main-count-text {
  width: 85%;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}

.export {
  padding: 8px 15px;
  font-size: 14px;
  width: 88px;
  border: 1px solid #02bc7c;
  background: #f0fbf7;
  color: #02bc7c;
}

.analyse-container-box {
  margin-top: 0;
  float: right;
  width: 73%;
  height: 750px;
}

.dialogcenter-content-font {
  margin-left: 10px;
}

.numbox {
  position: relative;

  .loadingWiat {
    margin-left: 15px;
  }

  .numbox_search {
    min-width: 480px;
    height: 250px;
    background-color: white;
    position: absolute;
    top: 50px;
    z-index: 999;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2);

    .websitebox_search_top {
      line-height: 33px;
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
      box-sizing: border-box;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .websitebox_search_body {
      position: relative;
      width: 100%;
      height: 200px;
      overflow: auto;
      box-sizing: border-box;
      padding-left: 10px;
      padding-top: 16px;

      .websitebox_search_body_right {
        width: 64%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-left: 1px solid #d8d8d8;
        box-sizing: border-box;
        padding-left: 10px;
        padding-top: 16px;
        overflow: auto;
      }
    }
  }
}

.websitebox {
  position: relative;

  .loadingWiat {
    margin-left: 15px;
  }

  .websitebox_search {
    min-width: 240px;
    height: 250px;
    background-color: white;
    position: absolute;
    top: 50px;
    z-index: 999;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2);

    .websitebox_search_top {
      line-height: 33px;
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
      box-sizing: border-box;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .websitebox_search_body {
      width: 100%;
      height: 200px;
      overflow: auto;
      box-sizing: border-box;
      padding-left: 10px;
      padding-top: 16px;
    }
  }
}

.warningRules {
  width: 100%;
  margin-bottom: 20px;

  .warningRules_words {
    display: flex;

    .warningRules_span {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      position: relative;
      top: 5px;
      flex-shrink: 0;
      width: 73px;
    }

    .warningRules_words_content {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.navcolor {
  color: #2e59ec;
}

::v-deep.el-checkbox {
  margin-right: 30px;
}

.el-checkbox {
  margin-bottom: 12px;
}

.warningRules_tag {
  margin-right: 15px;
  font-size: 14px;
  margin-bottom: 10px;
  background-color: #F5F7FB;
  border-radius: 3px;
  padding: 2px 3px 3px 10px;
  line-height: 24px;
  color: #555;
}

.warningRules_tag span {
  font-size: 14px;
  vertical-align: middle;
  color: #C44D66;
  margin-left: 5px;
  cursor: pointer;
}

.prohibit {
  cursor: not-allowed;
}

.tonalityInputList {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.tonalityInputList-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.tonalityInputList-tab {
  display: flex;
  align-items: center;
}

.tonalityInputList-col {
  padding: 6px 8px;
  background: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;

  img {
    margin-left: 3px;
    cursor: pointer;
  }
}

.tonalityInputList-col+.tonalityInputList-col {
  margin-left: 16px;
}

.tonality-content {
  background-color: #F4F5FA;
  padding: 16px;
}

.tonality-tab {
  padding: 6px 8px;
  background: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

.tonality-tab.active {
  background: #2E59EC;
  color: #fff;
}

.tonality-tab:hover {
  color: #2E59EC;
}

.tonality-tab.active:hover {
  color: #FFFFFF;
}

.tonality-tab+.tonality-tab {
  margin-left: 16px;
}

.dialogcenter-content-left {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.inspect-expression {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 14px;
  color: #2E59EC;
  cursor: pointer;
}
</style>
